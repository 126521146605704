import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const dieu = () => (
  <Layout>
    <SEO title="Sentier et Chemin - Dieu" />
    <h3 className='underline-title'>Dieu</h3>
    <br />
    <br />
    <div className="table-responsive">
      <p>
        Dieu seul porte son seul nom<br />
        Aux autres Il laisse tous les autres noms<br />
        Les noms aux évocations louangeuses<br />
      </p>
      <p>
        Dieul seul porte son seul nom<br />
        Aux puissants Il donne forces armées<br />
        Aussi donne-t-Il la valeur à celui<br />
        Qui n'a ni glaive ni lance<br />
      </p>
      <p>
        Seul Dieu est Dieu seul<br />
        Celui qui donna au guerrier philistin<br />
        Ses six coudées de taille et terrifiants équipements:<br />
        Cuirasse à écailles casque et jambière de bronze<br />
      </p>
      <p>
        Dieu seul est Lui-même<br />
        Le même Souverain Absolu<br />
        Qui ridiculisa les équipements du géant philistin<br />
        Par une fronde et une pierre du patre-homoncule judéen<br />
      </p>
      <p>
        Dieu est Dieu<br />
        À Lui seul revient son nom<br />
        Dieu n'est pas les autres<br />
        Les autres ne seront point Dieu<br />
      </p>
      <p>
        Ouf! ...<br />
        Heureusement!<br />
      </p>
    </div>
    <br />
    <br />
    <Link to="/sentier-et-chemin-table-des-matieres">Table des matières</Link>
  </Layout>
)

export default dieu
